import React, { useState, useEffect, useContext } from "react";
import { SignInContainer } from "./TermCondition.Styled";
import {
  MyProfileHeading,
  MyProfileText,
  ProfileDivider,
  AgentSection,
  ProfileInfoCard,
  CardPad,
} from "./TermCondition.Styled";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { PageWrap, PageContent } from "../../MenzillPrivatePages/PrivatesElements";
import axios from "../../../axios";
import Overlay from "../../../components/Overlay";
import { LanguageContext } from "../../../utils/language/Language";

const HomePageSection = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const defaultLanguage = window.localStorage.getItem("rcml-lang") || "en";

  const getTermCondition = async (val) => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`/page/type/4/${val}`);
      setIsLoading(false)
      setTableData(data);
    } catch (error) {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getTermCondition(defaultLanguage);
  }, []);
  
  const { dictionary } = useContext(LanguageContext);

  return (
    <>
      <PageWrap>
        <PageContent>
          <SignInContainer>
            <MyProfileHeading>
              <MyProfileText>{dictionary.menzilString.Contact_Us}</MyProfileText>
            </MyProfileHeading>
            <ProfileDivider>
              <AgentSection>
                <ProfileInfoCard>
                  <CardPad dangerouslySetInnerHTML={{ __html: tableData }}>
                  </CardPad>
                </ProfileInfoCard>
              </AgentSection>
            </ProfileDivider>
          </SignInContainer>
        </PageContent>
      </PageWrap>

      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePageSection));
