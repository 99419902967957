import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

// Import Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Import Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Global CSS
import "./App.css";
import "./index.css";

// Import Redux
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actionTypes from "./store/actions";
import { fetchToken, onMessageListener } from "./utils/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get } from "lodash";
import { LanguageProvider } from "./utils/language/Language";
import Overlay from "./components/DotLoader";
import Contact_Us from "./pages/MenzillPublicPages/TermCondition/Contact_Us";

// Import Private Layout Routes

// Public Layout Routes

const Navbar = lazy(() => import("./components/Navbar"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const HomePage = lazy(() => import("./pages/MenzillPublicPages/HomePage"));
const Social = lazy(() => import("./pages/MenzillPublicPages/Authentication/Social"));
const AgentProfile = lazy(() => import("./pages/MenzillPublicPages/Authentication/AgentProfile"));
const TermCondition = lazy(() => import("./pages/MenzillPublicPages/TermCondition/Term_Condition"));
const PrivacyPolicy = lazy(() => import("./pages/MenzillPublicPages/TermCondition/Privacy_Policy"));
const AboutUs = lazy(() => import("./pages/MenzillPublicPages/TermCondition/About"));
const Faq = lazy(() => import("./pages/MenzillPublicPages/TermCondition/Faq"));
const ChangePassword = lazy(() => import("./pages/MenzillPublicPages/TermCondition/ChangePassword"));
const Profile = lazy(() => import("./pages/MenzillPrivatePages/Profile/Profile"));
const MyProfile = lazy(() => import("./pages/MenzillPrivatePages/Profile/MyProfile"));
const ProfileDetails = lazy(() => import("./pages/MenzillPrivatePages/Profile/ProfileDetails"));
const ProfileNewDetails = lazy(() => import("./pages/MenzillPrivatePages/Profile/ProfileNewDetails"));
const PostProperty = lazy(() => import("./pages/MenzillPrivatePages/PostProperty/PostProperty"));
const ServiceArea = lazy(() => import("./pages/MenzillPrivatePages/ManageServiceArea/ServiceArea"));
const AddServiceArea = lazy(() => import("./pages/MenzillPrivatePages/ManageServiceArea/AddServiceArea"));
const PropertyDetails = lazy(() => import("./pages/MenzillPrivatePages/PropertyDetails"));
const ViewPrescription = lazy(() => import("./pages/MenzillPrivatePages/Prescription/ViewPrescription"));
const MyProperties = lazy(() => import("./pages/MenzillPrivatePages/MyProperties"));
const SearchProperty = lazy(() => import("./pages/MenzillPrivatePages/SearchProperty/SearchProperty"));
const EditProperty = lazy(() => import("./pages/MenzillPrivatePages/PostProperty/EditProperty"));
const PropertyRequest = lazy(() => import("./pages/MenzillPrivatePages/PropertyRequest/PropertyRequest"));
const SavedProperty = lazy(() => import("./pages/MenzillPrivatePages/SaveProperties"));
const AgentFinder = lazy(() => import("./pages/MenzillPrivatePages/AgentFinder"));
const FinderDetail = lazy(() => import("./pages/MenzillPrivatePages/AgentFinder/FinderDetails"));
const Notification = lazy(() => import("./pages/MenzillPrivatePages/Notification/Notification"));
const PostalCodes = lazy(() => import('./pages/MenzillPublicPages/PostalCodes/PostalCodes'));

const PrivateOnlyRoute = ({ userData, component: Component, isOpen, toggle, isAdmin, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <>
          <Suspense fallback={<Overlay />}>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            {!isAdmin && <Navbar toggle={toggle} />}
            <Component {...props} />
          </Suspense>
        </>
      );
    }}
  />
);

const WebisteRouter = ({ component: Component, isOpen, toggle, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <>
          <Suspense fallback={<Overlay />}>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Component {...props} />
          </Suspense>
        </>
      );
    }}
  />
);

const App = (props) => {
  // console.log("prop", props);
  const { userData, setNotificationData } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [deviceToken, setDeviceToken] = useState();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const param = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [isAdmin, seIsAdmin] = useState(param.admin);

  useEffect(() => {
    fetchToken(setDeviceToken);
  }, [deviceToken]);
  onMessageListener().then((payload) => {
    setNotificationData({
      isNotification: payload,
    });
    toast(<Msg title={get(payload, "data.title", "")} body={get(payload, "data.body", "")} />);
  });
  const Msg = ({ title, body }) => (
    <>
      <p style={{ color: "black" }}>{title}</p>
      <p style={{ color: "#aeb3ba" }}> {body} </p>
    </>
  );

  return (
    <LanguageProvider>
      <>
        <Switch>
          <WebisteRouter exact path="/" component={HomePage} isOpen={isOpen} toggle={toggle} />
          <WebisteRouter exact path="/agent-profile" component={AgentProfile} isOpen={isOpen} toggle={toggle} />
          <PrivateOnlyRoute exact path="/term-condition" component={TermCondition} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/privacy-policy" component={PrivacyPolicy} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/faq" component={Faq} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/about-us" component={AboutUs} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/contact-us" component={Contact_Us} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/change-password" component={ChangePassword} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/profile" component={Profile} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/my-profile" component={MyProfile} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/profile-details" component={ProfileDetails} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute
            exact
            path="/profile-new-details"
            component={ProfileNewDetails}
            userData={userData}
            toggle={toggle}
          />
          <PrivateOnlyRoute exact path="/post-property" component={PostProperty} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/edit-property/:id" component={EditProperty} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute
            exact
            path="/property/:id"
            component={PropertyDetails}
            userData={userData}
            toggle={toggle}
            isAdmin={isAdmin}
          />
          <PrivateOnlyRoute exact path="/my-properties" component={MyProperties} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/search-property" component={SearchProperty} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/manage-service-area" component={ServiceArea} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/add-service-area" component={AddServiceArea} userData={userData} toggle={toggle} />
          {userData?.settings?.is_subscription_enable && <PrivateOnlyRoute exact path="/prescription" component={ViewPrescription} userData={userData} toggle={toggle} />}
          <PrivateOnlyRoute exact path="/property-request" component={PropertyRequest} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/save-property" component={SavedProperty} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/agent-finder" component={AgentFinder} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/agent-finder/:id" component={FinderDetail} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/notification" component={Notification} userData={userData} toggle={toggle} />
          <PrivateOnlyRoute exact path="/postal-codes" component={PostalCodes} userData={userData} toggle={toggle} />
          <Redirect exact path="*" to="/" component={HomePage} />
        </Switch>
        <ToastContainer theme="colored" />
      </>
    </LanguageProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    locationData: state.locations,
    defaultState: state.defaultState,
    userData: state.userData,
    notificationDataState: state.notificationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setLocations: (updatedValue) => {
      dispatch({
        type: actionTypes.GET_LOCATIONS,
        locationData: updatedValue,
      });
    },
    setNotificationData: (updatedValue) => {
      dispatch({
        type: actionTypes.NOTIFICATION_DATA,
        updateNotificationData: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
