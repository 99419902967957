import React, { useContext } from "react";
import axios from "axios";
import { API_URL } from "./constants/Statics";
import { LanguageContext } from "./utils/language/Language";

// import { compose } from "redux";
import { toast } from "react-toastify";

const instance = axios.create({
  baseURL: API_URL,
});

// console.log("tt", window.localStorage.getItem("rcml-lang"));

//customer and admin secure
// instance.defaults.headers.common["language"] = window.localStorage.getItem("rcml-lang") || "en";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common["Accept-Language"] = window.localStorage.getItem("rcml-lang");
if (localStorage.MenzilAccessToken) {
  const JWT_token = localStorage.MenzilAccessToken;
  instance.defaults.headers.common["Authorization"] = `bearer ${JWT_token}`;
} else {
  instance.defaults.headers.common["Authorization"] = null;
}

instance.interceptors.request.use(
  async (config) => {
    const JWT_token = localStorage.MenzilAccessToken;

    config.headers.common["Authorization"] = JWT_token ? `bearer ${JWT_token}` : null;
    instance.defaults.headers.common["Accept-Language"] = window.localStorage.getItem("rcml-lang");

    return config;
  },
  (error) => {
    toast.error(`${error.response.data.message}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (error.response.status === 401) {
      localStorage.removeItem("MenzilAccessToken");
      localStorage.removeItem("MenzilUserData");
      //window.location.pathname = "/";
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (val) => val,
  (err) => {
    if (err.response.status === 401 && localStorage.getItem("MenzilAccessToken")) {
      alert(err.response.data?.message || "Your session is logged out!");
      localStorage.removeItem("MenzilAccessToken");
      localStorage.removeItem("MenzilUserData");
      window.location.pathname = "/";
    }

    return Promise.reject(err);
  }
);

export default instance;
