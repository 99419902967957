import * as actionTypes from "./actions";

const initialState = {
  userData: null,
  defaultState: {
    isLogin: "",
    isSignup: "",
    isForget: "",
    isOtp: "",
    isForgetOtp: "",
    isReset: "",
    isDetails: "",
    isApproval: "",
    isProcess: "",
    isDisapprove: "",
    isSellProperty: "",
    isAgentApprove: "",
    isAgentPrescription: "",
  },
  locations: [],
  sidebar: true,
  notificationData: {
    isNotification: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        userData: action.updatedUser,
      };
    case actionTypes.UPDATE_DEFAULT:
      return {
        ...state,
        defaultState: action.updateDefault,
      };
    case actionTypes.GET_LOCATIONS:
      return {
        ...state,
        locations: action.locationData,
      };
    case actionTypes.UPDATE_SIDEBAR:
      return {
        ...state,
        sidebar: action.updateSidebar,
      };
    case actionTypes.NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.updateNotificationData,
      };

    default:
      return state;
  }
};

export default reducer;
