import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getDatabase, ref, set, push, child, get, onValue } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDqRVqusLqyp3AHO_eH0KhWW3buIGruwWY",
  authDomain: "menzill.firebaseapp.com",
  projectId: "menzill",
  storageBucket: "menzill.appspot.com",
  messagingSenderId: "136854074372",
  appId: "1:136854074372:web:3afacd5d8d84dc087f3151",
  measurementId: "G-FG5DR9EJ7W",
};

// to access database
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();
const db = getDatabase();

// const db = firebaseApp.firestore();

// const auth = firebase.auth()

const firebaseMessaging = getMessaging(app);

const fetchToken = async (setTokenFound) => {
  // console.log(setTokenFound);
  return getToken(firebaseMessaging, {
    vapidKey: "BPo9pH1W_pOQLeFdKiAL83XsFWBLoj7zNdADyUCuqumQaXdoUjULDLcsF2TN5marI9Pcf2_FmQZl2EjcV9dOUfY",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // console.log('No registration token available. Request permission to generate one.');
        setTokenFound(currentToken);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
// const requestForToken = () => {
//   return getToken(firebaseMessaging, { vapidKey: `BH9x5FVAN-Nev8wUZW12_GL14jGnmYBtsj-xXPPhY5X-gthO97tPvxv6_V227cBGpY6Qhj9keLij9keS7t3RiMo` })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log('current token for client: ', currentToken);
//         // Perform any other neccessary action with the token
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
// };

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(firebaseMessaging, (payload) => {
      // console.log(payload);
      resolve(payload);
    });
  });
const FCM_KEY = "BPo9pH1W_pOQLeFdKiAL83XsFWBLoj7zNdADyUCuqumQaXdoUjULDLcsF2TN5marI9Pcf2_FmQZl2EjcV9dOUfY";

export { firestore, db, app, fetchToken, onMessageListener, FCM_KEY };
