import styled from "styled-components/macro";

export const PageWrap = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* @media(max-width: 1255px){
        width: 90%;
    } */
`;

export const PageContent = styled.div`
  height: auto;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  margin-top: 8rem;
  width: 100%;
  /* @media(max-width: 1255px){
        width: 1000px;
    } */
  @media (max-width: 768px) {
    margin-top: 7rem;
    width: 100%;
  }
  /* background-color: beige; */
`;

export const PageContentContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;
