import React from "react";
import styled, { keyframes, withTheme } from "styled-components";
// import { FlexWrapper } from "./StyledComponents";

const OverWrapper = styled.div(
  (props) => `
    display: flex;
    flex-direction: ${props.direction || "column"};
    justify-content: center;
    align-items: center;
    position:${props.position ? props.position : "fixed"} ;
    width: 100%;
    height: ${props.viewheight ? props.viewheight : "fixed"};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props.backgroundColor ? "rgba(0, 0, 0, 0.5)" : ""};
    z-index: 99;
    cursor: pointer;
`
);
const spin = keyframes`
0% { width: 64px; height: 64px; transform: rotate(0deg) }
50% { width: 30px; height: 30px; transform: rotate(180deg) }
100% { width: 64px; height: 64px; transform: rotate(360deg) }
`;
const Circle = styled.div`
  width: 64px; 
  height: 64px; 
  position: relative; 
  background-image: linear-gradient(#ED7D2B 16px, transparent 0) , linear-gradient(#FF3D00 16px, transparent 0) , linear-gradient(#FF3D00 16px, transparent 0) , linear-gradient(#ED7D2B 16px, transparent 0); 
  background-repeat: no-repeat; 
  background-size: 16px 16px; 
  background-position: left top , left bottom , right top , right bottom; 
  animation: ${spin} 1s linear infinite;
`;

function Overlay(props) {
  const { position, direction, backgroundColor, viewheight } = props;
  return (
    // <div></div>
    <OverWrapper position={position} direction={direction} backgroundColor={backgroundColor} viewheight={viewheight}>
      <Circle />
    </OverWrapper>
  );
}

export default withTheme(Overlay);
