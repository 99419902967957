import en from "./en.json";
import ar from "./ar.json";
import tr from "./tr.json";

export const dictionaryList = { en, ar, tr };

export const languageOptions = {
  en: "English",
  ar: "عربى",
  tr: "عربى",
};
