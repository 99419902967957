import styled from "styled-components/macro";
import HomeBack from "../../../images/homeBgFull.png";
import signInliving from "../../../images/signInliving.png";

export const SignInContainer = styled.div`
  height: auto;
  width: 100%;
`;

export const SignRow = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;
    align-items: center; */
  max-width: 1333px;

  ${
    "" /* @media screen and (max-width :1562px) {
        width: 88%;
    }

    @media screen and (max-width :1500px) {
        width: 93%;
    } */
  }

  @media screen and (max-width :768px) {
    grid-template-areas: auto auto;
  }
`;

export const SignColumn1 = styled.div`
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  @media (max-width: 1366px) {
    align-items: center;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SignColumn2 = styled.div`
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
  ${"" /* overflow: hidden; */}
  ${"" /* background: url(${HomeBack}) no-repeat center top / cover; */}
    @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LivingImage = styled.img`
  height: auto;
  width: 90%;
  margin-top: 3.5rem;
  margin-left: 4rem;
`;

export const SigninDetailsWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5rem;
  ${"" /* align-items: center; */}
  ${"" /* padding : 0rem 16rem; */}
`;

export const LoginTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoginHeading = styled.h2`
  color: #000000;
  font-size: 1.6rem;
  /* text-align: left; */
  ${"" /* text-align: ${({ alignCenter }) => (alignCenter ? 'center' : 'left')}; */}
  ${"" /* font-weight: bold; */}
    
    @media screen and (max-width: 768px) {
    font-size: 1.6em;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.3em;
  }
`;

export const SignHeadingOne = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  font-family: "Montserrat";
  text-align: left;
  color: #ff9900;
  letter-spacing: 3.2px;
  text-transform: uppercase;
  opacity: 1;
`;

export const SigninWelcome = styled.div`
  height: auto;
  display: flex;
  align-items: center;
`;

export const SigninWelcomeHeading = styled.div`
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
  color: #000000;
  margin-right: 0.8rem;
  margin-top: 0.4rem;
  font-weight: 800;
  font-size: 2.8rem;
  font-family: "Montserrat";
`;

export const SigninWelcomeIcon = styled.img`
  height: 35px;
  width: 35px;
`;

export const SignInform = styled.div`
  margin-top: 1.8rem;
`;

export const SignInButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

export const RoleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RoleWrapBtn = styled.button`
  padding: 0rem 1rem;
  height: 40px;
  background: ${({ role }) => (role ? "#004CFF" : "#E6E6E6")};
  color: ${({ role }) => (role ? "white" : "black")};
  border-radius: 4px;
  opacity: 1;
  border: none;
  margin-left: 20px;
  cursor: pointer;
`;

export const SignupDetailsWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${"" /* padding : 0rem 16rem; */}
`;

export const SocialCorner = styled.div`
  position: absolute;
  top: 38%;
  right: 3rem;

  @media screen and (max-width: 1366px) {
    display: none;
  }
`;

export const SocialCornerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SocialBoxWrap = styled.div`
  margin: 0.7rem 0rem;
`;

export const SocialBox = styled.img`
  height: 23px;
  width: 23px;
`;

export const SiginDescription = styled.div`
  font: normal normal normal 17px/32px Montserrat;
  letter-spacing: 0px;
  color: #31353b;
  text-align: left;
  opacity: 1;
  margin-top: 1rem;
`;

export const SearchBox = styled.div`
  display: flex;
  box-shadow: 0px 16px 20px #0000000f;
  border: 1px solid #dadada;
  border-radius: 4px;
  opacity: 1;
  margin-top: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 90%;
  margin-bottom: 2.5rem;

  /* @media(max-width: 768px){
        display: none;
    } */
`;

export const InputSearch = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  /* border-left: 0.5px solid #4993FF; */
  align-items: center;
`;

export const InputFeild = styled.input`
  height: auto;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: 0.8rem;
  font-family: "Montserrat";
  letter-spacing: 0px;
  opacity: 0.8;
  padding: 0 9px;
  color: #31353b;
`;

export const SearchIcon = styled.img`
  margin-left: 1rem;
  width: 3%;
`;

export const SearchIconWrap = styled.div``;

export const SearchButton = styled.button`
  box-shadow: rgb(0 0 0 / 19%) 0px 0px 10px;
  border-radius: 4px;
  opacity: 1;
  margin: 0.1em;
  white-space: nowrap;
  padding: 1.2em 2.5em;
  border: none;
  background: rgb(235, 125, 53);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.2s ease-in-out 0s;
  text-decoration: none;
  font: normal normal medium 16px/22px Montserrat;
  letter-spacing: 0px;
`;

export const PartnerText = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Montserrat";
  color: #31353b;
  text-align: left;
  opacity: 1;
  margin-top: 3rem;
`;

export const SponserWrap = styled.div`
  margin-top: 1.5rem;
  display: flex;
  height: auto;
  justify-content: space-between;
  align-item: center;
  width: 90%;
`;

export const SponserImg = styled.img`
  height: 60%;
`;

// profile css

export const MyProfileHeading = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  margin: 1rem 0rem;
`;

export const MyProfileText = styled.div`
  font-weight: 600;
  font-size: 2rem;
  font-family: "Montserrat";
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const AgentColumn1 = styled.div`
  width: 25%;
  height: 100vh;
`;

export const AgentColumn2 = styled.div`
  width: 50%;
  height: 100vh;
  background: #f8fbff 0% 0% no-repeat padding-box;
`;

export const AgentColumn3 = styled.div`
  width: 25%;
  height: 100vh;
  background: #f8fbff 0% 0% no-repeat padding-box;
`;

export const ProfileSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
`;

export const AccountSection = styled.div`
  /* padding: 0rem 1rem; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background: #f8fbff 0% 0% no-repeat padding-box;
`;

export const AccountTab = styled.div`
  margin: 0.5rem 0rem;
  padding: 0.8rem 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* background: ${({ bgColor }) => (bgColor ? "#0288D1" : "#FFFFFF")}; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  align-items: center;
`;

export const AccountTabText = styled.div`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  color: #31353b;
  font-weight: 500;
  color: ${({ bgColor }) => (bgColor ? "#FFFFFF" : "#1A1A1A")};
  text-transform: capitalize;
  opacity: 1;
  margin-left: 0.5rem;
`;

export const ProfileDivider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProfileSection1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
  /* background: #f8fbff 0% 0% no-repeat padding-box; */
  margin: 0rem 2rem;
  padding: 1rem 2rem;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProfileSection2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  margin: 0rem 2rem;

  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;

export const ProfileSection3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 30%;
  margin: 0rem 2rem;

  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;

export const ProfIcon = styled.img`
  height: 27px;
  width: 27px;
`;

export const ProfileInfoCard = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 1.5rem;
  /* margin-right: 0.5rem; */
  flex-direction: column;
  background-color: #f8fbff;
  border-radius: 6px;
  padding: 1rem;
  width: 100%;
`;

export const CardTitle = styled.div`
  height: auto;
  width: auto;
  text-align: left;
  font: 500 18px/19px Nunito;
  letter-spacing: 0.18px;
  color: #363636;
  opacity: 1;
`;

export const CardQuan = styled.div`
  height: auto;
  width: auto;
  display: flex;
  justify-content: space-between;
  text-align: left;
  font: 700 24px/26px Nunito;
  letter-spacing: 0.24px;
  color: #363636;
  opacity: 1;
`;

export const CardDetailsSection = styled.div`
  height: auto;
  width: 100%;
  display: flex;
`;

export const CardDetails = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0.9rem;
`;

export const CardImage = styled.img`
  cursor: pointer;
  /* height: 27px;
    width: 27px; */
`;

export const ProfileImage = styled.img`
    width: 9%;
    border-radius: 52px;
    height: 85%;
`;

export const UserName = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
  font-family: "Montserrat";
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
`;

export const UserPhone = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #8e8e8f;
`;

export const UserEmail = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #004cff;
  letter-spacing: 0px;
  opacity: 1;
`;

export const UserMember = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Montserrat";
  color: #31353b;
  display: flex;
  align-items: center;
`;

export const MemberSince = styled.div`
  font-weight: 600;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #000000;
`;

export const MemberDate = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #31353b;
  margin-left: 0.6rem;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: end;
`;

export const InfoTitle = styled.div`
  font-weight: 500;
  font-size: 0.9rem;
  font-family: "Montserrat";
  color: #000000;
`;

export const InfoContent = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #31353b;
`;

export const SigninWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  width: auto;
`;

export const SigninBorder = styled.div`
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 1.5rem;
`;

export const SigninText = styled.div`
  font-size: 0.9rem;
  font-family: "Montserrat";
  letter-spacing: 0px;
  opacity: 1;
  margin-right: 1.4rem;
  padding: 0.6rem 0rem;
  cursor: pointer;
  border-bottom: ${({ logintype }) => (logintype ? "2px solid #eb7d35" : "")};
  font-weight: ${({ logintype }) => (logintype ? "500" : "400")};
  color: ${({ logintype }) => (logintype ? "#000000" : "#7F7F7F")};
`;

export const NewAccountText = styled.div`
  color: #8e8e8f;
  font-weight: 400;
  font-size: 0.9rem;
  font-family: "Montserrat";
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  /* border-bottom : 2px solid #eb7d35; */
  border-bottom: ${({ logintype }) => (logintype ? "2px solid #eb7d35" : "")};
  font-weight: ${({ logintype }) => (logintype ? "600" : "")};
  color: ${({ logintype }) => (logintype ? "#000000" : "#7F7F7F")};
  padding: 0.6rem 0rem;
`;

export const InfoData = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlanInfoCard = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 1.5rem;
  /* margin-right: 0.5rem; */
  flex-direction: column;
  background-color: #15396d;
  box-shadow: 0px 4px 12px #1d483114;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  opacity: 1;
`;

export const StarImage = styled.img``;

export const PlanTitle = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  font-family: "Montserrat";
  color: #ffffff;
`;

export const PlanDescription = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #ffffff;
  line-height: 1.6;
`;

export const PlanMonth = styled.div`
  font-weight: 300;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #ffffff;
`;

export const DetailInfoCard = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 1.5rem;
  /* margin-right: 0.5rem; */
  flex-direction: column;
  background-color: #fff9e5;
  box-shadow: 0px 4px 12px #1d483114;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  opacity: 1;
  margin-top: 1.5rem;
`;

export const DetailMessage = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #000000;
  line-height: 1.6;
`;

export const LoginButton = styled.button`
  margin: 0.4em;
  border-radius: 2px;
  width: 100%;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "0.5em 2em" : "0.7em 1.5em")};
  background: ${({ primary }) => (primary ? "transparent" : "#ED7D2B")};
  border: 1px solid ${({ primary }) => (primary ? "#FFFFFF" : "#ED7D2B")};
  color: ${(dark) => (dark ? "#F1F1F1" : "#F1F1F1")};
  font-size: ${({ fontBig }) => (fontBig ? "1.2rem" : "1rem")};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-radius: 4px;

  /* &:hover {
            transition: all 0.2s ease-in-out;
            background: ${({ primary }) => (primary ? "#FF4001" : "transparent")};
            border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
            color: ${(dark) => (dark ? "#F1F1F1" : "#F1F1F1")};
        } */

  @media screen and (max-width: 768px) {
    margin: 0.5em;
    margin-left: 0;
    padding: ${({ big }) => (big ? "0.5em 2em" : "0.6em 1.5em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "0.9em")};
  }

  @media screen and (max-width: 480px) {
    margin: 0.5em;
    margin-left: 0;
    padding: ${({ big }) => (big ? "0.5em 2em" : "0.4em 1.2em")};
    font-size: ${({ fontBig }) => (fontBig ? "1.2em" : "0.8em")};
  }
`;

export const ProfileDetailsDivider = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
`;

export const ProfileDetailsForm = styled.div`
  display: flex;
  align-item: center;
  background: #f8fbff 0% 0% no-repeat padding-box;
  flex-direction: column;
  width: 70%;
  padding: 2rem 3rem;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 2rem;
  }
`;

export const BasicDetails = styled.div`
  font-weight: 600;
  font-size: 1.5rem;
  font-family: "Montserrat";
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const LanguageChip = styled.div`
  border: 1px solid #f3f3f4;
  border-radius: 5px;
  font: 500 15px / 23px Nunito;
  letter-spacing: 0px;
  color: rgb(54, 54, 54);
  opacity: 1;
  padding: 0.4rem;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
  background-color: #f3f3f4;
`;

export const LanguageSection = styled.div`
  display: flex;
  align-items: center;
`;

export const WebsiteLink = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Montserrat";
  letter-spacing: 0px;
  opacity: 1;
  color: #004cff;
  margin-right: 0.7rem;
`;

export const RejectedInfoCard = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 1.5rem;
  /* margin-right: 0.5rem; */
  flex-direction: column;
  background-color: #fee9e9;
  box-shadow: 0px 4px 12px #1d483114;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  opacity: 1;
  margin-top: 1.5rem;
`;

export const ApprovedInfoCard = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 1.5rem;
  /* margin-right: 0.5rem; */
  flex-direction: column;
  background-color: #e5faf1;
  box-shadow: 0px 4px 12px #1d483114;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  opacity: 1;
  margin-top: 1.5rem;
`;

export const RejectedMessage = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #f32a2a;
  line-height: 1.6;
`;

export const ApprovedMessage = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  font-family: "Montserrat";
  color: #06c972;
  line-height: 1.6;
`;

export const AgentBtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AgentButton = styled.button`
  margin: 0.4em;
  border-radius: 2px;
  white-space: nowrap;
  padding: 0.8em 12em;
  background: #ed7d2b;
  border: #ed7d2b;
  color: #f1f1f1;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 2rem;
  font-family: 'Montserrat';
  font-weight: 500;
  /* margin-bottom: 1rem; */

  @media screen and (max-width: 480px) {
    padding: 0.7em 6em;
  }
  `;

export const ProfileEditCard = styled.div`
  height: auto;
  display: flex;
  margin-bottom: 1.5rem;
  /* margin-right: 0.5rem; */
  flex-direction: column;
  background-color: #f8fbff;
  border-radius: 6px;
  padding: 3.4rem;
  width: 100%;
  @media (max-width: 426px) {
    padding: 2rem;
  }
`;

export const EditProfileText = styled.div`
  font-weight: 600;
  font-size: 1.4rem;
  font-family: "Montserrat";
  color: #000000;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 2rem;

  /* @media screen and (max-width :768px) {
        font-size: 1.2rem;
    } */
`;



export const ProfileImgWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;



export const AgentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  /* margin: 0rem 2rem; */
  @media (max-width: 545px) {
    width: 95%;
  }
  `


export const CardPad = styled.div`
    /* padding: 2rem; */
    line-height: 2rem;
`;


export const PartnerFirstP = styled.span`
  font-weight: 600;
  font-size: 1rem;
  font-family: "Montserrat";
  color: #242021;
  opacity: 1;
`

export const PartnerFirstDesc = styled.span`
  font-weight: 400;
  font-size: 1rem;
  font-family: "Montserrat";
  color: #242021;
  opacity: 1;
`


